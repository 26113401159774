@import "styles/main.scss";

.back {
  display: flex;
  align-items: center;
  cursor: pointer;

  .backIcon {
    margin-right: 10px;
  }
}