@import "styles/main.scss";

.dashboard {
  .sideNav {
    @include flexBetween;
    @include flexColumn;
    position: fixed;
    height: 100vh;
    width: 290px;
    .dashLogo {
      padding: 40px 40px 20px 40px;
      & img {
        width: Hug (120.82px);
      }
    }
    .sideNavLinks {
      @include flexColumn;
      @include gap(20px);
      padding: 40px;
      border-top: 1px solid $boderGrey;
    }
    .dashLinks {
      @include flex;
      @include flexAlign;
      @include gap(15px);
      color: $grey2;
    }
    .active {
      color: $dark;
      // & svg path {
      //   fill: $dark;
      // }
    }
    .logoutBtn {
      padding: 50px;
    }

    @include for-phone-only {
      display: none;
    }
  }
  & .mobileNav {
    display: none;

    & .logoDark {
      width: 79.95px;
    }
  }
  .dashboardRight {
    min-height: 100vh;
    padding: 40px;
    margin-left: 290px;
    background-color: $purpleXlight;

    & h4 {
      margin-bottom: 10px;
    }

    & h5 {
      font-weight: 600;
    }
    & .flex {
      @include flexBetween;
    }
    & .buyBtn {
      width: 278px;
      height: 52px;
      font-weight: 600;
    }
    & .bottom {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  @include for-phone-only {
    & .mobileNav {
      @include flexBetween;
      @include flexAlign;
      height: 12vh;
      padding: 30px;
    }
    & .dashboardRight {
      margin-left: 0;
      padding: 20px;
      padding-top: 10px;
      background-color: white;
      & .name {
        display: none;
      }
      & .flex {
        @include flexColumn;
      }
      & .bottom {
        & .buyBtn {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}

.sideMobile {
  position: fixed;
  height: 100%;
  width: 80%;
  background-color: white;
  padding-top: 40px;

  & .header {
    padding: 20px;
    color: $dark;

    & a {
      color: $dark;
    }
    & .welcomeText {
      font-size: $textSmall;
    }
    & p {
      font-size: $textXSmall;
    }
    & .nameProfile {
      @include flexBetween;
      @include flexAlign;
      margin: 10px 0 5px 0;
    }
    & .meter {
      @include flex;
      @include flexAlign;

      & p {
        margin-right: 10px;
      }
    }
  }

  & .sideNavLinks {
    @include flexColumn;
    @include gap(20px);
    padding: 20px;
    border-top: 1px solid $boderGrey;
    overflow: scroll;
  }
  & .dashLinks {
    @include flex;
    @include flexAlign;
    @include gap(15px);
    color: $grey2;
    font-size: $textSmall;
  }
  & .active {
    color: $dark;
    & svg path {
      fill: $dark;
    }
  }
}

.active {
  color: black;
  & svg {
    stroke: $dark;
  }
}
