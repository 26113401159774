@import "styles/main.scss";

.otherSignin {
  margin-top: 40px;
  .option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .or {
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      width: 40%;
      height: 1px;
      background-color: $boderGrey;
    }
  }
  .socialButton {
    font-size: 14px;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
  }
  .btnContainer {
    margin-top: 30px;
    width: 100%;
  }
  @include for-phone-only {
    .option {
      span {
        width: 30%;
      }
    }
    .btnContainer {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}
