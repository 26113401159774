@import "styles/main.scss";

.navBar {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 130px;
  color: #fff;
  height: 10vh;

  & .logoLight {
    @include for-phone-only {
      display: none;
    }
  }

  & .logoDark {
    display: none;
    width: 79.95px;
    @include for-phone-only {
      display: block;
    }
  }
.hide {
  display: none;
}
  & ul {
    // display: none;
    display: flex;
    gap: 20px;
    align-items: center;

    & a {
      color: #fff;
    }

    @include for-phone-only {
      display: none;
    }
  }

  .menu {
    display: none;
    @include for-phone-only {
      display: block;
    }
  }

  @include for-phone-only {
    background-color: #fff;
    padding: 20px 20px;
    height: 10vh;
  }
}

.mobileNav {
  @include flexBetween;
  @include flexColumn;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 20px;
  z-index: 2000;

  .mobileNavIcon {
    @include flexBetween;
    @include flexAlign;
  }
  .mobileNavLinks {
    @include justifyCenter;
    @include flexColumn;
    @include flexAlign;
    @include gap(40px);
    height: 100%;
    // margin-top: 30px;

    & a {
      color: #fff
    }
  }

  .authBtn {
    @include flexBetween;

    .mobileNavLogin {
      @include justifyCenter;
      @include flexAlign;
      width: 100%;
    }
  }
  & .btn {
    display: flex;
    justify-content: center;
    gap: 50px;

    & a {
      color: white;
    }
  }
}
