@import "styles/main.scss";

.editContainer {

  & p {
    font-weight: 500;
    font-size: $textSmall;
  }
  & .btnContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.profileView {
  & .yourProfile {
    background: linear-gradient(90deg, #8d8d8d 2.85%, #000000 97.46%);
    color: #fff;
    padding: 40px 20px;
    border-radius: 16px;
    margin-bottom: 40px;
  }
  & .profileContainer {
    padding-left: 20px;
    padding-right: 80px;
    & .profileDetails {
      margin-bottom: 20px;
      & .profileItem {
        display: grid;
        grid-template-columns: 200px 1fr;
        margin-bottom: 20px;
        gap: 10px;

        & .title {
          color: $grey1;
        }
        & .text {
          font-weight: 500;
        }
      }
    }
  }

  @include for-phone-only {
    & .yourProfile {
      padding: 20px 20px;
      margin-bottom: 30px;
      font-size: $titleMedium;
    }
    & .profileContainer {
      padding: 10px;
      & .profileDetails {
        & .profileItem {
          @include grid(2);
          & .title {
            font-size: $textSmall;
          }
          & .text {
            @include flex;
            justify-content: start;
            font-size: $textSmall;
          }
        }
      }
    }
  }
}
