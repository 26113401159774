@import "styles/main.scss";

.footer {
  @include flexColumn;
  @include flexBetween;
  background-color: #000;
  color: #fff;
  padding: 100px 130px 30px 130px;

  .footerTop {
    // @include grid(2);
    display: flex;
    justify-content: space-between;

    .footerText {
      max-width: 420px;
      margin-top: 20px;
    }

    .footerRight {
      // @include grid(2);
      // @include flexBetween;

      .footerLinks {
        @include flexColumn;
        @include gap(40px);
        display: none;
      }

      .location {
        // @include flexBetween;
        @include flexColumn;
        @include gap(20px);
      }
      h5 {
        margin-bottom: 20px;
      }
    }
  }
  .footerBottom {
    // @include grid(2);
    display: flex;
    justify-content: space-between;
    @include flexAlign;
    margin-top: 10vh;
    .footerSocials {
      @include flex;
      @include gap(20px);

      .footerSocial {
        @include justifyCenter;
        @include flexAlign;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;

        & a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .privacyTerms {
      display: flex;
      gap: 20px;
    }
    .privacyTerms a {
      color: white;
    }
    .footerCopy {
      // width: 200px;
      font-size: $textXSmall;
    }
  }
  @include for-phone-only {
    padding: 60px 20px;

    .footerTop {
      @include grid(1);
      .footerRight {
        @include grid(1);
        order: -1;

        .footerLinks {
          // @include flexRow;
          // flex-wrap: wrap;
          // margin-bottom: 20px;
          // row-gap: 10px;
          display: none;
        }
        .location {
          @include gap(40px);
          margin-bottom: 40px;
        }
      }
    }

    .footerBottom {
      @include grid(1);
      @include gap(40px);
      margin-top: 40px;
      .footerSocials {
        @include flex;
        @include gap(10px);
      }
      .privacyTerms {
        flex-direction: column;
      }
      .footerCopy {
        text-align: center;
      }
    }
  }
}
