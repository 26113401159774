@import "styles/main.scss";

.table {
  .tableTitle {
    @include flexBetween;
  }
  & table {
    width: 100%;
    margin-top: 20px;

    & tbody {
      width: 100%;
    }
  }

  @include for-phone-only {
     .table {
      display: none;
    }
  }
}
