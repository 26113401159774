@import "styles/base/_variables.scss";

h1 {
  font-size: 45px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0.01em;
}
h2 {
  font-size: 34px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
  line-height: 28px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
  font-weight: 400;
}
p {
  font-weight: 400;
  line-height: 24px;
}

.smallText {
  font-size: $textSmall;
}
