@import "styles/main.scss";

.checkDetails {
  width: 513px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  & .container {
    width: 371px;

    & .details {
      display: grid;
      grid-template-columns: 150px 1fr;
      gap: 20px;
      margin-bottom: 20px;
      align-self: center;

      & .title {
        font-size: $textXSmall;
        color: $grey2;
        margin-bottom: 5px;
      }
    }

    & .text {
      font-size: $textSmall;
      font-weight: 500;
    }
    & .paymentCont {
      align-self: flex-start;
      margin: 50px 0 40px 0;
    }
  }

  @include for-phone-only {
    width: 100%;
    & .container {
      width: 100%;

      & .details {
        grid-template-columns: 1fr;
      }
    }
  }
}

.sendRequest {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 513px;

  & h5 {
    font-weight: 600;
  }
  & .inputs {
    width: 278px;
    margin: 40px 0 20px 0;
  }

  & .btnContainer {
    margin-top: 30px;
  }

  @include for-phone-only {
    width: 100%;
    & h5 {
      text-align: center;
    }
  }
}
