@import "styles/main.scss";

.header {
  & .topHeader {
    height: 80vh;
    background-image: linear-gradient(
        180deg,
        #000000 0%,
        rgba(29, 30, 36, 0) 100%
      ),
      url("../../../src/assets/images/header.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 50px;
    color: #fff;
    & .top {
      margin-bottom: 30px;
      gap: 30px;
    }
    & .leftHeader {
      justify-content: center;

      & h1 {
        font-size: 45px;
        color: #fff;
        line-height: 60px;
        margin-bottom: 20px;
        & span {
          color: $yellow;
        }
      }
      & p {
        line-height: 24px;
      }
    }
    & .comingSoon {
      width: 700px;
      justify-content: center;
      text-align: left;;

      & h1 {
        font-size: 45px;
        line-height: 60px;
        margin-bottom: 30px;
        & span {
          color: $yellow;
        }
      }
      & h4 {
        margin-top: 30px;
      }
    }
    & .rightHeader {
      width: 618px;
      border: 3px solid white;
      background-image: linear-gradient(
        135.59deg,
        rgba(255, 255, 255, 0.2) 1.28%,
        rgba(255, 255, 255, 0.2) 96.26%
      );
      padding: 50px 20px;

      & p {
        width: 221px;
        text-align: center;
        font-size: $textXSmall;
        line-height: 18px;
        margin: 10px 0 30px 0;
      }

      & .btn {
        width: 60%;
        margin-top: 20px;
        height: 52px;
      }
    }

    .tagLine ul {
      display: flex;
      margin-top: 5px;
      font-size: 22px;
    }
    .tagLine li {
      border-left: 1px solid white;
      padding: 0 20px;
    }
    .tagLine li:first-child {
      border-left: none;
      padding-left: 0;
      @include for-phone-only {
        padding-left: 20px;
      }
    }
  }
  & .bottom {
    background-color: $yellow;
    padding: 20px 40px;
    height: 10vh;

    & ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include for-phone-only {
    & .topHeader {
      height: 100%;
      padding: 10px;
      & .top {
        display: grid;
        grid-template-columns: 1fr;
      }
      & .leftHeader {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      & h1 {
        font-size: 40px;
        margin: 30px 0;
      }
      & .comingSoon {
        width: 100%;
        text-align: center;
      }
      .tagLine ul {
        font-size: 18px;
      }
      & .rightHeader {
        padding: 20px;
        width: 100%;

        & p {
          width: 221px;
          text-align: center;
          font-size: $textXSmall;
          line-height: 18px;
          margin: 10px 0 20px 0;
        }

        & .btn {
          width: 70%;
          margin-top: 20px;
        }
      }
    }
    & .bottom {
      display: flex;
      justify-content: center;
      padding: 20px;
      & ul {
        overflow-x: scroll;
        gap: 50px;
      }
    }
  }
}

.getStarted {
  padding: 100px;
  padding-bottom: 20px;

  & p {
    font-weight: 400;
  }
  & .getStartedTitle {
    position: relative;
    margin-bottom: 30px;
    z-index: 80;

    & img {
      position: absolute;
      left: 80px;
      bottom: -40px;
    }
  }
  & .details {
    background-color: #f6f5f4;
    padding: 30px;
    border-radius: 12px;
    z-index: 100;
    margin-bottom: 5vh;
    & .heading {
      width: 536px;
      & .logo {
        width: 79.95px;
      }
      & h4 {
        font-weight: 500;
      }
    }

    & .contents {
      background-color: #fff;
      border-radius: 12px;
      padding: 80px 50px;
      width: 85%;
      margin: auto;
      position: relative;
      margin-top: 30px;
      margin-bottom: 50px;

      & .happy {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
      }
      & .smallText {
        font-size: $textXSmall;
      }

      & h4 {
        font-weight: 600;
        margin: 20px 0;
      }

      & .content {
        margin-left: 50px;
        gap: 80px;

        & .green {
          background-color: $tealDark;
        }
        & .yellow {
          background-color: $mustard;
        }
        & .happy {
          margin-right: 20px;
        }
      }

      & .content2 {
        text-align: right;
        margin-top: 32vh;
        justify-content: space-between;
        z-index: 99;

        & .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 391px;
        }

        & .blue {
          background-color: $blueDark;
        }

        & .happy {
          margin-left: 20px;
        }
      }

      & .content3 {
        margin-top: 32vh;
      }
      & .curve {
        position: absolute;
        top: 80px;
      }
    }
  }
  @include for-phone-only {
    padding: 20px;
    & .getStartedTitle {
      text-align: center;

      & img {
        display: none;
      }
    }
    & .details {
      padding: 20px;
      margin-bottom: 5vh;
      & .heading {
        text-align: center;
        width: 100%;
      }

      & .contents {
        padding: 20px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 50px;

        & .happy {
          width: 50px;
          height: 50px;
        }
        & .smallText {
          font-size: $textXSmall;
        }

        & h4 {
          font-weight: 600;
          margin: 20px 0;
        }

        & .content {
          margin-left: 0px;
          gap: 30px;
          flex-direction: column;

          & .green {
            background-color: $tealDark;
          }
          & .yellow {
            background-color: $mustard;
          }
          & .happy {
            margin-right: 20px;
          }
        }

        & .content2 {
          flex-direction: column-reverse;
          text-align: left;
          margin-top: 30px;
          gap: 30px;

          & .right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-top: 50px;
          }

          & .blue {
            background-color: $blueDark;
          }

          & .happy {
            margin-left: 20px;
          }
        }

        & .content3 {
          margin-top: 50px;
        }
        & .curve {
          display: none;
        }
      }
    }
  }
}

.contactUs {
  display: flex;
  flex-direction: column;
  margin: 15vh 0;
  z-index: 99;

  & .tryPawa {
    margin-bottom: 10vh;
    & h1 {
      font-size: 60px;
      margin-bottom: 20px;
      line-height: 65px;
    }
    & p {
      margin-bottom: 20px;
    }
    & a {
      color: $blueDark;
    }
    & .tryBtn {
      height: 37px;
    }
  }
  & .title {
    margin-bottom: 5vh;
    & h1 {
      font-size: 60px;
      margin-bottom: 20px;
    }
  }
  & .contactContainer {
    padding: 10px;
    // grid-template-columns: 491px 1fr;
    width: 55%;
    margin: auto;
    position: relative;
    box-shadow: 14px 17px 40px 4px #7090b014;

    & .contactDetails {
      background-color: #000000;
      color: #fff;
      padding: 40px 30px 20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 12px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & h4 {
        font-weight: 500;
      }
      & p {
        color: #c9c9c9;
      }
      & ul {
        display: flex;
        flex-direction: column;
        gap: 30px;
        flex-grow: 1;
        margin-top: 80px;

        & li {
          max-width: 288px;
        }
      }
      & .social {
        display: flex;
        align-items: center;
        gap: 20px;
        & svg path {
          fill: white;
        }
        & .instagram {
          @include justifyCenter;
          @include flexAlign;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
        }
      }
      & .fullCircle {
        position: absolute;
        bottom: 60px;
        right: 60px;
        z-index: 100;
      }
      & .ellipse {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    & .contactForm {
      padding: 40px 50px;

      & .input {
        gap: 30px;
        margin-bottom: 30px;
      }
      & p {
        font-weight: 500;
      }
      & .btn {
        width: 259px;
        margin-top: 30px;
        margin-bottom: 12vh;
        z-index: 99;
      }
      & .subject {
        margin-bottom: 30px;
      }
    }
    & .send {
      position: absolute;
      bottom: -35px;
      right: 150px;
    }
  }

  @include for-phone-only {
    flex-direction: column-reverse;
    & .tryPawa {
      margin-bottom: 0px;
      & h1 {
        width: 80%;
        text-align: center;
      }
      & p {
        text-align: center;
        width: 90%;
      }
    }

    & .title {
      & p {
        text-align: center;
        width: 95%;
      }
    }
    & .contactContainer {
      grid-template-columns: 1fr;
      width: 100%;
      margin-bottom: 5vh;

      & .contactDetails {
        text-align: center;
        align-items: center;

        & ul {
          gap: 20px;
          margin: 50px 0;
        }
        & .fullCircle {
          bottom: 50px;
          right: 50px;
          width: 54px;
          height: 54px;
        }
        & .ellipse {
          width: 100px;
          height: 100px;
        }
      }

      & .contactForm {
        padding: 20px;
        margin-top: 30px;

        & .input {
          gap: 30px;
        }
        & p {
          font-weight: 500;
        }
        & .btn {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      & .send {
        bottom: 0px;
        width: 104.4px;
        height: 52.15px;
      }
    }
  }
}
