@import "styles/main.scss";

.button {
  border-radius: 7px;
  background-color: transparent;
  color: white;
  text-transform: capitalize;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 45px;
  font-weight: 500;
  cursor: pointer;

  &--primary {
    background-color: $yellow;
    color: $textDark;
  }
  &--secondary {
    background-color: $buttonDark;
    color: #fff;
  }
  &--dark {
    background-color: $dark;
    color: #fff;
  }
  &--social {
    background-color: $buttonScocial;
    color: $textDart2;
  }
  &--tertiary {
    background-color: $purpleXlight;
    color: $textDart2;
  }

  &--large {
    width: 100%;
    height: 65px;
    font-size: 22px;
  }
  &--small {
    width: 90px;
    height: 32px;
    font-size: $textSmall;
  }
  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  img {
    margin-right: 15px;
  }

  @include for-phone-only {
    &--large {
      height: 61px;
    }
    &--social {
      height: 40px;
    }
    img {
      width: 24px;
    }
  }
}
