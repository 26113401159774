@import "styles/main.scss";

.form_control {
  @include flexColumn;
  position: relative;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    top: 25px;
    right: 17px;
    content: url('../../assets/icons/down-white.svg');

  }
}

.form_control--no-margin {
  margin-bottom: 0;
}

.select {
  position: relative;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  font-size: $textSmall;
  color: black;
  padding: 10px;
  transition: all 0.4s;
  font-weight: 400;
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    border-color: $yellow;
  }
  &:focus-visible {
    outline: none;
  }
  // &::placeholder {
  //   /* Chrome, Firefox, Opera, Safari 10.1+ */
  //   color: rgba($color: $dark, $alpha: 0.4);
  //   font-size: pxToRem(14);
  //   opacity: 1; /* Firefox */
  // }

  // &:-ms-select-placeholder {
  //   /* Internet Explorer 10-11 */
  //   color: rgba($color: $dark, $alpha: 0.45);
  //   font-size: pxToRem(14);
  // }

  // &::-ms-select-placeholder {
  //   /* Microsoft Edge */
  //   color: rgba($color: $dark, $alpha: 0.45);
  //   font-size: pxToRem(14);
  // }
}
.whiteInput {
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}
.prefix {
  position: absolute;
  top: 12px;
  left: 10px;
}
.select--prefix {
  padding-left: 48px;
}

.select--error {
  border-color: red;
}

.error_message {
  font-size: $textXSmall;
  display: block;
  color: red;
  margin-top: 5px;
  font-weight: 500;
}
