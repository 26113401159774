@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import "styles/base/_variables.scss";
@import "styles/utils/_mixins.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

ul {
  list-style: none;
}

input {
  outline: none;
  &:focus {
    border-color: $yellow;
  }
  &:focus-visible {
    outline: none;
  }
}
label {
  font-size: $textXSmall;
}
a {
  text-decoration: none;
  color: $blueDark;
}
a:visited {
  color: $dark;
}
.hide {
  display: none;
}
.flex {
  display: flex;
}
.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.justifyEnd {
  display: flex;
  justify-content: flex-end;
}
.btnContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px;

  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px 30px;

  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}
.padding40 {
  padding: 40px;
  // @include for-phone-only {
  //   padding: 20px
  // }
}

.radio {
  @include gap(30px);
  margin-top: 10px;
  margin-bottom: 20px;
  @include for-phone-only {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.myLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.emptyTrans {
  text-align: center;
  font-weight: 500;
}
