@import "styles/main.scss";

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // height: 92vh;
  min-height: 90vh;

  @include for-phone-only {
    grid-template-columns: 1fr;
    padding: 15px;
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .checkDetails {
    width: 513px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  
    & .container {
      width: 371px;
  
      & .details {
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 20px;
        margin-bottom: 20px;
        align-self: center;
  
        & .title {
          font-size: $textXSmall;
          color: $grey2;
          margin-bottom: 5px;
        }
      }
  
      & .text {
        font-size: $textSmall;
        font-weight: 500;
      }
      & .paymentCont {
        align-self: flex-start;
        margin: 50px 0 40px 0;
      }
    }
  
    @include for-phone-only {
      width: 100%;
      & .container {
        width: 100%;
  
        & .details {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  

  //   .left {
  //     .backBtn {
  //       align-self: flex-start;
  //       margin-bottom: 5vh;
  //     }
  //     .leftTitle {
  //       width: 412px;
  //     }
  //     .leftTitle,
  //     .leftText {
  //       margin-bottom: 20px;
  //     }
  //     .leftContent {
  //       width: 388px;
  //     }
  //     .submitBtn {
  //       margin: 20px 0;
  //     }

  //     .forgot {
  //       display: flex;
  //       justify-content: flex-end;

  //       .link {
  //         color: $blueDark;
  //       }
  //     }

  //     @include for-phone-only {
  //       width: 100%;
  //       .leftTitle {
  //         width: 100%;
  //       }
  //       .leftContent {
  //         width: 100%;
  //       }
  //     }
  //   }
  //   .account {
  //     display: flex;
  //     align-items: center;
  //     margin-top: 30px;
  //     margin-bottom: 50px;
  //     justify-content: center;

  //     p {
  //       margin-bottom: 0;
  //       margin-left: 10px;
  //       color: $blueDark;
  //       cursor: pointer;
  //     }

  //     @include for-phone-only {
  //       margin-top: 10px;
  //     }
  //   }
  //   .copyright {
  //     font-size: $textXSmall;
  //   }

  //   .otpContainer {
  //     display: flex;
  //     justify-content: space-around;
  //     gap: 2px;
  //     margin: 10px 0;

  //     .otpInput {
  //       width: 33px;
  //       height: 35px;
  //       border-radius: 6.53px;
  //     }
  //   }

  //   @include for-phone-only {
  //     order: 1;
  //     padding-top: 40px;
  //     padding-bottom: 10px;

  //     .copyright {
  //       margin-top: 30px;
  //     }
  //   }
  // }
  .right {
    background: linear-gradient(#f6c500, #dd9a00);
    border-bottom-left-radius: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
      order: -1;
      margin-top: 20px;
      height: 180px;
      border-bottom-left-radius: 20px;

      .logoBig {
        height: 100px;
      }
    }
  }
}
.footer {
  display: none;
  @include for-phone-only {
    @include flex;
  }
}
