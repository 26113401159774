@import "styles/main.scss";

.policy {
  padding: 50px;
  font-size: 14px;

  & h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  & h3,
  em {
    font-size: 16px;
    margin-top: 30px;
    font-weight: 600;
    display: block;
  }
  em {
    margin-top: 0px;
  }

  & ul {
    margin: 20px 40px;
    list-style: disc;

    & span {
      font-weight: 600;
    }
  }

  & a {
    color: dodgerblue;
  }

  @include for-phone-only {
    padding: 16px;

    & h2 {
      font-size: 24px;
    }
  }
}
.terms {
  h3 {
    margin-bottom: 10px;
  }
}
