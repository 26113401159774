@import "styles/main.scss";

.tableRow {
  @include flexBetween;
  padding: 10px 30px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 18px 40px 0px #7090b01f;
    border-radius: 20px;
  }

  & td {
    align-items: center;
    width: calc(75% / 4);

    &:first-child {
      width: 25%;
    }
  }
  & .disco {
    max-width: 900px;
    & img {
      width: 40px;
      margin-right: 10px;
    }
  }
  & .text {
    font-weight: 600;
    flex-grow: 1;
  }
  & .flex {
    @include flex;

    & p {
      margin-left: 5px;
    }
  }
  & .time {
    @include justifyCenter;
    color: $grey1;
    align-self: center;
  }
  & .status {
    align-items: center;
    justify-content: center;
  }
  & .Successful {
    color: $teal;
  }
  & .Failed {
    color: $pink;
  }
  & .amount {
    @include justifyCenter;
    align-self: center;
    & p {
      font-weight: 500;
    }
  }
  & .reorder {
    @include flex;
    justify-content: flex-end;
  }
  @include for-phone-only {
    .tableRow {
      display: none;
    }
  }
}
