@import "styles/main.scss";

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.7);

  & .close {
    display: flex;
    justify-content: flex-end;
    padding-top: 50px;
    margin-right: 50px;
    cursor: pointer;
  }
  & .modalBody {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}

.checkDetails {
  width: 513px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  & .container {
    width: 371px;

    & .details {
      display: grid;
      grid-template-columns: 150px 1fr;
      gap: 20px;
      margin-bottom: 20px;
      align-self: center;

      & .title {
        font-size: $textXSmall;
        color: $grey2;
        margin-bottom: 5px;
      }
    }

    & .text {
      font-size: $textSmall;
      font-weight: 500;
    }
    & .paymentCont {
      align-self: flex-start;
      margin: 50px 0 40px 0;
    }
  }

  @include for-phone-only {
    width: 80%;
    margin-top: 0;
    max-height: 350px;
    overflow: scroll;
    padding: 20px 30px;
    & .container {
      width: 100%;

      & .details {
        grid-template-columns: 1fr;
      }
    }
  }
}

.statusModal {
  padding-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & img {
    width: 30px;
  }
  & h4 {
    font-size: 20px;
    margin-top: 10px;
    color: $tealDark;
  }
  & p {
    margin: 20px 0;
  }
}
