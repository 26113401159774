@import "styles/main.scss";

.dashboardHome {
  height: 100%;
  .homeTop {
    @include gap(20px);
    display: grid;
    grid-template-columns: 1fr;
    height: 345px;

    .totalPayments {
      display: grid;
      grid-template-columns: 300px 1fr;
      height: 100%;

      & .calender {
        @include flex;
        @include flexAlign;
        width: fit-content;
        background-color: $purpleXlight;
        padding: 5px 10px;
        border-radius: 4px;
        color: $grey1;

        & p {
          margin-left: 5px;
        }
      }
      & h2 {
        margin-top: 20px;
      }
      & p {
        font-size: $textSmall;
        color: $grey1;
      }

      & .onTrack {
        @include flex;
        @include flexAlign;
        margin-top: 15px;
        & p {
          color: $teal;
          font-size: $textXSmall;
          margin-left: 5px;
          font-weight: 600;
        }
      }

      & .totalAmount {
        width: 100%;
      }

      .paymentChart {
        @include flexColumn;
        @include flexBetween;

        & .barChart {
          @include justifyCenter;
          background-color: $purpleXlight;
          padding: 0 2px;
          border-radius: 4px;
          align-self: flex-end;
        }
        & .lineChart {
          height: 100%;
          width: 100%;
        }
      }
    }
    .pie {
      @include flexBetween;
      @include flexColumn;
    }
    .pieTitle {
      @include flexBetween;
      @include flexAlign;

      margin-bottom: 30px;

      & .title {
        font-weight: 600;
      }
    }
    .pieData {
      @include flexBetween;

      box-shadow: 0px 18px 40px 0px #7090b01f;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 0px 10px 20px 10px;

      & .pieItem {
        text-align: center;
      }

      & .status {
        @include flex;
        @include flexAlign;

        & p {
          font-size: $textXSmall;
          margin-left: 5px;
        }

        & span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        & .done {
          background-color: $teal;
        }

        & .fail {
          background-color: $pink;
        }
      }
      & h6 {
        font-weight: 600;
      }
    }

    @include for-phone-only {
      @include grid(1);

      & .totalPayments {
        @include grid(1);
      }
    }
  }
  .homeBottom {
    margin-top: 30px;
  }

  @include for-phone-only {
    & .homeTop {
      height: 100%;
    }
    & .homeBottom {
      display: none;
    }
  }
  .transactionMobile {
    display: none;
    margin-top: 30px;
    @include for-phone-only {
      display: block;
    }
  }
}
