@import "styles/main.scss";

.transItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grey4;
  padding: 20px 0;
  gap: 5px;

  & .itemCenter {
    flex-grow: 1;
  }
  & .amount {
    text-align: right;
  }
  & .successAmount {
    font-weight: 600;
    color: $teal;
  }
  & .failedAmount {
    font-weight: 600;
    color: $pink;
  }
  & .reorderBtn{
    align-self: flex-start;
  }
}
