$purpleDark: #281c9d;
$purple: #5655b9;
$purpleLight: #a8a3d7;
$purpleXlight: #f2f1f9;
$mustard: #ccba1b;
$yellow: #ffe922;
$tealDark: #0c91ae;
$blueDark: #0146a3;
$buttonDark: #162d3a;
$buttonScocial: #f3f9fa;

$textDark: #1a1c21;
$textDart2: #313957;
$dark: #343434;
$grey1: #898989;
$grey2: #989898;
$grey3: #cacaca;
$grey4: #e0e0e0;
$boderGrey: #cfdfe2;

$pink: #ff4267;
$blue: #0890fe;
$orange: #ffaf2a;
$teal: #52d5ba;
$orangeDark: #fb6b18;

$titleLarge: 24px;
$titleMedium: 20px;
$titleNormal: 16px;
$textSmall: 14px;
$textXSmall: 12px;
