@import "styles/main.scss";

.transactionDetails {
  background-color: #fff;
  width: 300px;
  padding: 30px;
  margin: 20px 0;
  & p {
    font-size: 9.94px;
  }
  & .logo {
    width: 56.86px;
  }
  & .token {
    text-align: center;
    border: 1px dashed black;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    letter-spacing: 2px;

    & h3 {
      font-size: 13.91px;
    }
  }
  & .item {
    border-bottom: 1px dashed $grey2;
    padding: 10px 0;
  }
  & .title {
    color: $grey2;
  }
  & .bottomText {
    font-size: 8px;
    font-weight: 300;
    line-height: 9.6px;
    margin: 20px 0;
  }
}
