@import "styles/main.scss";

.input {
  @include flexColumn;
  margin-bottom: 20px;
  width: 100%;

  input {
    border: none;
    border-bottom: 1px solid #000;
    padding: 10px;
    width: 100%;
  }
  .inputContainer {
    position: relative;
  }
  .whiteInput {
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    background-color: transparent;
  }
  & .errorMessage {
    color: red;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
  }
  & .loader {
    position: absolute;
    right: 10px;
    bottom: 0;
    width: 20px;
  }
}
.otpContainer {
  display: flex;
  justify-content: space-around;
  gap: 2px;
  margin-top: 10px;

  .otpInput {
    width: 33px;
    height: 35px;
    border-radius: 6.53px;
  }
}