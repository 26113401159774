@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin grid($column) {
  display: grid;
  grid-template-columns: repeat($column, 1fr);
}

@mixin gap($gap) {
  gap: $gap;
}

@mixin flex {
  display: flex;
}

@mixin flexBetween {
  @include flex();

  justify-content: space-between;
  // align-items: center;
}

@mixin flexAround {
  @include flex();

  justify-content: space-around;
  align-items: center;
}

@mixin flexRow {
  @include flex();

  flex-direction: row;
}

@mixin flexColumn {
  @include flex();

  flex-direction: column;
}

@mixin flexAlign {
  align-items: center;
}

@mixin justifyCenter {
  @include flex();
  justify-content: center;
}
