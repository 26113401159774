@import "styles/main.scss";

.card {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;

  @include for-phone-only {
    width: 100%;
    box-shadow: 0px 4px 30px 0px #3629b712;
    border-radius: 30px;
  }
}
